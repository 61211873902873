@if (loginDisplay$ | async; as loginDisplay) {
	@if (claims$ | async; as claims) {
		<h2 i18n>Claims</h2>
		<div id="table-container">
			<table mat-table class="mat-elevation-z8" [dataSource]="claims">
				<!-- Claim Column -->
				<ng-container matColumnDef="claim">
					<th i18n *matHeaderCellDef mat-header-cell>Claim</th>
					<td *matCellDef="let element" mat-cell>
						{{ element.claim }}
					</td>
				</ng-container>

				<!-- Value Column -->
				<ng-container matColumnDef="value">
					<th i18n *matHeaderCellDef mat-header-cell>Value</th>
					<td *matCellDef="let element" mat-cell>
						{{ element.value }}
					</td>
				</ng-container>

				<!-- Value Column -->
				<ng-container matColumnDef="description">
					<th i18n *matHeaderCellDef mat-header-cell>Description</th>
					<td *matCellDef="let element" mat-cell>
						{{ element.description }}
					</td>
				</ng-container>

				<tr
					*matHeaderRowDef="displayedColumns; sticky: true"
					mat-header-row
				></tr>
				<tr
					*matRowDef="let row; columns: displayedColumns"
					mat-row
				></tr>
			</table>
		</div>
	}
} @else {
	<mat-card class="card-section">
		<mat-card-title i18n
			>Angular single-page application built with MSAL
			Angular</mat-card-title
		>
		<mat-card-subtitle i18n
			>Sign-in with Azure AD B2C and get an ID Token</mat-card-subtitle
		>
		<mat-card-content i18n
			>This sample demonstrates how to configure MSAL Angular to sign-in,
			sign-out and protect a route.
		</mat-card-content>
	</mat-card>
}
