import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { PageTitleService } from '@tcb/app/services/page-title.service';

@Component({
	selector: 'tcb-components',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './components.component.html',
	styleUrl: './components.component.scss',
})
export class ComponentsComponent {
	private pageTitleService = inject(PageTitleService);

	constructor() {
		this.pageTitleService.setTitle('Debug - Playground');
	}
}
