/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { isProduction$ } from './utility/isProduction$';

window.setProduction = (isProduction: boolean) => {
	isProduction$.next(isProduction);
};

const appRef = bootstrapApplication(AppComponent, appConfig);

appRef
	.then((app) => app.bootstrap(MsalRedirectComponent))
	// eslint-disable-next-line no-console
	.catch((err) => console.error(err));
