import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoggedInUserService {
	public userInitial = new BehaviorSubject<string>('');

	public userDetails = new BehaviorSubject<UserDetails>({
		contactName: '',
		email: '',
	});

	constructor() {}
}
export interface UserDetails {
	contactName: string;
	email: string;
}
