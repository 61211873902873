import { HttpClient } from '@angular/common/http';
import { InjectionToken, inject } from '@angular/core';
import {
	AddressJson,
	AgentJson,
	ContactJson,
	CreateAgentResponseJson,
	GetAgentDetailResponseJson,
	GetAgentsListForUserResponseJson,
} from '@tcb/model';
import { map, type Observable } from 'rxjs';
import { TCB_API_BASE } from '../TCB_API_BASE.token';

export interface IAgent {
	agentId: number;
	title: string;
	contact?: ContactJson;
	address?: AddressJson;
}

/**
 * Query interface for IAgentsService.getAgents. Used to create strongly-typed queries.
 * @prop {number?} agentId ID of IAgent to read
 * @prop {number?} pageIdx Index of page to read
 */
export interface IAgentsQuery {
	agentId?: number;
	pageIdx?: number;
}

export interface IAgentsService {
	createNewAgent(agent: AgentJson): Observable<CreateAgentResponseJson>;
	getAgent(agentId: number): Observable<GetAgentDetailResponseJson>;
	getAllAgents(): Observable<GetAgentsListForUserResponseJson>;
}

export const AgentsService = new InjectionToken<IAgentsService>(
	'IAgentsService',
	{
		providedIn: 'root',
		factory: () => new HttpAgentsService(),
	},
);

import { StrictlyImplements } from '@tcb/app/interfaces/strictly-implements.interface';
//eslint-disable-next-line @typescript-eslint/no-unused-vars
type _ = StrictlyImplements<keyof IAgentsService, keyof HttpAgentsService>;

export class HttpAgentsService implements IAgentsService {
	/**Base API URL */
	private readonly API_BASE = inject(TCB_API_BASE);

	private httpClient = inject(HttpClient);

	/**POST Request to Create the New Agent Profile */
	createNewAgent(agent: AgentJson): Observable<CreateAgentResponseJson> {
		return this.httpClient.post(`${this.API_BASE}/Agents`, agent).pipe(
			map((response: CreateAgentResponseJson) => {
				if (response.success) {
					return response;
				} else {
					throw response;
				}
			}),
		);
	}

	getAgent(agentId: number): Observable<GetAgentDetailResponseJson> {
		return this.httpClient.get(`${this.API_BASE}/Agents/${agentId}`);
	}

	/**GET Request to fetch all Agents */
	getAllAgents(): Observable<GetAgentsListForUserResponseJson> {
		return this.httpClient
			.get<GetAgentsListForUserResponseJson>(
				`${this.API_BASE}/Agents?PageIndex=1`,
			)
			.pipe(
				map((response) => {
					if (!response) {
						throw new Error('No response from Get All Agents API.');
					}

					if (!response.success) {
						throw new Error(
							'Get All Agents API returned an error.',
						);
					}

					if (!response.agents) {
						throw new Error('No Agents are found.');
					}

					if (!response.agents.agentList) {
						throw new Error(
							'No Agents are created, please create new Agent.',
						);
					}

					return response;
				}),
			);
	}
}
