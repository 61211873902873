import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, filter, map, switchMap, takeUntil } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import {
	NavigationEnd,
	Router,
	RouterLink,
	RouterLinkActive,
	RouterOutlet,
} from '@angular/router';
import {
	MSAL_GUARD_CONFIG,
	MsalBroadcastService,
	MsalGuardConfiguration,
	MsalService,
} from '@azure/msal-angular';
import {
	AccountInfo,
	AuthenticationResult,
	EventMessage,
	EventType,
	InteractionStatus,
	InteractionType,
	PopupRequest,
	RedirectRequest,
} from '@azure/msal-browser';

import { AsyncPipe, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { PageTitleService } from '@tcb/app/services/page-title.service';
import { TelemetryService } from '@tcb/app/services/telemetry/telemetry.service';
import { TcbContactUsComponent } from '@tcb/app/views/tcb-contact-us/tcb-contact-us.component';
import { environment } from '@tcb/environment';
import { isProduction$ } from '@tcb/utility/isProduction$';

import { b2cPolicies } from './auth.config';
import { AgentsService } from './services/model/agents/agents.service';
import { LoggedInUserService } from './services/model/logged-in-user/logged-in-user.service';

@Component({
	selector: 'tcb-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [
		MatToolbarModule,
		RouterLink,
		MatDividerModule,
		MatButtonModule,
		MatIconModule,
		RouterLinkActive,
		MatMenuModule,
		NgIf,
		RouterOutlet,
		AsyncPipe,
	],
})
export class AppComponent implements OnInit, OnDestroy {
	private readonly _destroying$ = new Subject<void>();

	private readonly dialog = inject(MatDialog);
	private readonly pageTitleService = inject(PageTitleService);
	private readonly authService = inject(MsalService);
	private readonly msalBroadcastService = inject(MsalBroadcastService);
	private readonly router = inject(Router);
	private readonly telemetry = inject(TelemetryService);
	private readonly agentsService = inject(AgentsService);
	private readonly loggedInUserService = inject(LoggedInUserService);

	public avatarInitial: string = '';
	public hideHelpMenu: boolean = environment.menuAccess.helpMenu;

	debug$ = isProduction$.pipe(map((isProduction) => !isProduction));
	isIframe = window !== window.parent && !window.opener;
	loginDisplay = false;
	title$ = this.pageTitleService.title$;

	constructor(
		@Inject(MSAL_GUARD_CONFIG)
		private msalGuardConfig: MsalGuardConfiguration,
	) {
		this.pageTitleService.setTitle(
			$localize`UL Wireless CB Application Filing`,
		);

		this.telemetry.logMetric('app.init', performance.now(), {
			app: 'TCB',
			isProduction: environment.production,
		});
	}

	$pageViews = this.router.events
		.pipe(
			filter(
				(event): event is NavigationEnd =>
					event instanceof NavigationEnd,
			),
			// this cannot be combineLatest because changing the title
			// should not trigger a page view
			switchMap((navigationEnd) =>
				this.pageTitleService.title$.pipe(
					map((title) => ({ title, navigationEnd })),
				),
			),
		)
		.subscribe(({ title, navigationEnd }) => {
			this.telemetry.logPageView(title, navigationEnd.urlAfterRedirects);
		});

	checkAndSetActiveAccount() {
		/**
		 * If no active account set but there are accounts signed in, sets first account to active account
		 * To use active account set here, subscribe to inProgress$ first in your component
		 * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
		 */
		const activeAccount = this.authService.instance.getActiveAccount();

		if (
			!activeAccount &&
			this.authService.instance.getAllAccounts().length > 0
		) {
			const accounts = this.authService.instance.getAllAccounts();
			// add your code for handling multiple accounts here
			this.authService.instance.setActiveAccount(accounts[0]);
		}
	}

	// contact us dialog open event
	launchContactUsDialog() {
		const dialogRef = this.dialog.open(TcbContactUsComponent, {
			restoreFocus: false,
			width: '770px',
			data: {},
		});

		// opens the dialog won't be in the DOM any more when the dialog closes.
		dialogRef.afterClosed().subscribe(() => {});
	}

	// editProfile() {
	// 	const b2cEnv = environment.azure
	// 		.b2c as unknown as Azure.B2C.ConfigBuilder<''>;
	// 	const editProfileId = b2cEnv.getPolicyIdByPolicyType(
	// 		Azure.B2C.PolicyType.EditProfile,
	// 	);

	// 	const editProfileFlowRequest: RedirectRequest | PopupRequest = {
	// 		authority: editProfileId,
	// 		scopes: [],
	// 	};

	// 	this.login(editProfileFlowRequest);
	// }

	// unsubscribe to events when component is destroyed
	ngOnDestroy(): void {
		if (this.$pageViews) {
			this.$pageViews.unsubscribe();
		}
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}

	ngOnInit(): void {
		this.isIframe = window !== window.parent && !window.opener;
		this.setLoginDisplay();
		this.setUserInitials();
		this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

		/**
		 * You can subscribe to MSAL events as shown below. For more info,
		 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
		 */
		this.msalBroadcastService.inProgress$
			.pipe(
				filter(
					(status: InteractionStatus) =>
						status === InteractionStatus.None,
				),
				takeUntil(this._destroying$),
			)
			.subscribe(() => {
				this.setLoginDisplay();
				this.checkAndSetActiveAccount();
				this.setUserInitials();
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.LOGOUT_SUCCESS,
				),
				takeUntil(this._destroying$),
			)
			.subscribe(() => {
				this.setLoginDisplay();
				this.checkAndSetActiveAccount();
			});

		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.LOGIN_SUCCESS,
				),
				takeUntil(this._destroying$),
			)
			.subscribe((result: EventMessage) => {
				const payload = result.payload as AuthenticationResult;
				this.authService.instance.setActiveAccount(payload.account);
				const firstName = payload.account.idTokenClaims?.[
					'given_name'
				] as string;
				const lastName = payload.account.idTokenClaims?.[
					'family_name'
				] as string;
				const userInitial = `${firstName.charAt(0)}${lastName.charAt(
					0,
				)}`;
				const contactName = `${firstName} ${lastName}`;
				const email = payload.account.idTokenClaims?.[
					'email'
				] as string;
				sessionStorage.setItem('userInitial', userInitial);
				this.loggedInUserService.userInitial.next(userInitial);
				this.loggedInUserService.userDetails.next({
					contactName: contactName,
					email: email,
				});
				/**Validating the logged user is new user or the old user using GET All Agents List API */
				this.agentsService.getAllAgents().subscribe((data) => {
					if (!data) {
						return;
					}

					if (!data.agents) {
						return;
					}

					if (!data.agents.totalCount) {
						return this.router.navigate(['/agent/profile/new']);
					} else {
						return this.router.navigate(['/dashboard']);
					}
				});
			});
	}

	//redirect to dashboard
	redirectToDashboard() {
		if (this.loginDisplay) {
			this.router.navigate(['/dashboard']);
		}
	}

	setUserInitials() {
		this.loggedInUserService.userInitial.subscribe((response) => {
			if (response) {
				this.avatarInitial = response;
			} else {
				this.avatarInitial = sessionStorage.getItem(
					'userInitial',
				) as string;
			}
		});
	}

	login() {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.authService
					.loginPopup({
						...this.msalGuardConfig.authRequest,
					} as PopupRequest)
					.subscribe((response: AuthenticationResult) => {
						this.authService.instance.setActiveAccount(
							response.account,
						);
					});
			} else {
				this.authService
					.loginPopup()
					.subscribe((response: AuthenticationResult) => {
						this.authService.instance.setActiveAccount(
							response.account,
						);
					});
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.authService.loginRedirect({
					...this.msalGuardConfig.authRequest,
				} as RedirectRequest);
			} else {
				this.authService.loginRedirect();
			}
		}
	}

	name: string | undefined;
	accounts: AccountInfo[] | undefined;

	setLoginDisplay() {
		this.loginDisplay =
			this.authService.instance.getAllAccounts().length > 0;
		this.name = this.authService.instance.getActiveAccount()
			? this.authService.instance.getActiveAccount()?.username
			: 'Unknown';
		this.accounts = this.authService.instance.getAllAccounts();
	}

	changePassword() {
		const changePassword = b2cPolicies.authorities.resetPassword;
		this.authService.loginRedirect(changePassword as RedirectRequest);
	}
}
