import { HttpClient } from '@angular/common/http';
import { InjectionToken, inject } from '@angular/core';
import {
	CreateAttachmentRequestJson,
	CreateAttachmentResponseJson,
	DeleteAttachmentResponseJson,
	DeleteAttachmentsResponseJson,
	GetAttachmentListResponseJson,
} from '@tcb/model';

import { type Observable } from 'rxjs';
import { TCB_API_BASE } from '../TCB_API_BASE.token';

export interface IAttachment {
	attachmentId: number;
	title: string;
}

export interface GetAttachmentDetailResponse {
	success?: boolean;
	message?: string;
	validationErrors?: Array<string> | null;
	fileProcessingJobStatus?: IAttachment;
	totalCount?: number;
}
/**
 * Query interface for IAttachmentsService.getAttachments. Used to create strongly-typed queries.
 * @prop {number?} attachmentId ID of IAttachment to read
 * @prop {number?} pageIdx Index of page to read
 */
export interface IAttachmentsQuery {
	attachmentId?: number;
	pageIdx?: number;
}

export interface IAttachmentsService {
	// getAttachments(query?: IAttachmentsQuery): Observable<IAttachment[]>;
	// createAttachment(
	// 	attachment: Omit<IAttachment, 'attachmentId'>,
	// ): Observable<IAttachment>;
	// getAttachment(attachmentId: number): Observable<IAttachment>;
	// updateAttachment(attachment: IAttachment): Observable<IAttachment>;
	// deleteAttachment(attachment: IAttachment): Observable<boolean>;
	createFileProcessJobId(
		fileProcessJobRequest: CreateAttachmentRequestJson,
	): Observable<CreateAttachmentResponseJson>;

	uploadFileToServer(
		fileData: FormData,
		jobID: string,
	): Observable<CreateAttachmentResponseJson>;

	getAttachmentsList(
		applicationID: number,
	): Observable<GetAttachmentListResponseJson>;

	getDeviceCharAttachment(
		applicationID: number,
	): Observable<GetAttachmentListResponseJson>;

	getAttachmentsDetail(
		jobId: string,
	): Observable<GetAttachmentDetailResponse>;

	deleteFile(Id: number): Observable<DeleteAttachmentResponseJson>;

	deleteAllFiles(
		applicationID: number,
	): Observable<DeleteAttachmentsResponseJson>;

	sendMail(mailData: object): Observable<DeleteAttachmentsResponseJson>;
}

export const AttachmentsService = new InjectionToken<IAttachmentsService>(
	'IAttachmentsService',
	{
		providedIn: 'root',
		factory: () => new HttpAttachmentsService(),
	},
);

import { StrictlyImplements } from '@tcb/app/interfaces/strictly-implements.interface';
type _ = StrictlyImplements<
	keyof IAttachmentsService,
	keyof HttpAttachmentsService
>;

export class HttpAttachmentsService implements IAttachmentsService {
	private httpClient = inject(HttpClient);
	private readonly API_BASE = inject(TCB_API_BASE);

	createFileProcessJobId(
		fileProcessJobRequest: CreateAttachmentRequestJson,
	): Observable<CreateAttachmentResponseJson> {
		return this.httpClient.post(
			`${this.API_BASE}/Attachments`,
			fileProcessJobRequest,
		);
	}

	uploadFileToServer(
		fileData: FormData,
		jobID: string,
	): Observable<CreateAttachmentResponseJson> {
		return this.httpClient.post(
			`${this.API_BASE}/Attachments/${jobID}/upload`,
			fileData,
		);
	}

	getAttachmentsList(
		applicationID: number,
	): Observable<GetAttachmentListResponseJson> {
		return this.httpClient.get(
			`${this.API_BASE}/Attachments/${applicationID}`,
		);
	}

	getDeviceCharAttachment(
		applicationID: number,
	): Observable<GetAttachmentListResponseJson> {
		return this.httpClient.get(
			`${this.API_BASE}/Attachments/${applicationID}?attachmentTypeId=2`,
		);
	}
	getAttachmentsDetail(
		jobId: string,
	): Observable<GetAttachmentDetailResponse> {
		return this.httpClient.get(
			`${this.API_BASE}/Attachments/${jobId}/status`,
		);
	}

	deleteFile(Id: number): Observable<DeleteAttachmentResponseJson> {
		return this.httpClient.delete(`${this.API_BASE}/Attachments/${Id}`);
	}

	deleteAllFiles(
		applicationID: number,
	): Observable<DeleteAttachmentsResponseJson> {
		return this.httpClient.delete(
			`${this.API_BASE}/Attachments/application/${applicationID}`,
		);
	}

	sendMail(mailData: object): Observable<DeleteAttachmentsResponseJson> {
		return this.httpClient.post(`${this.API_BASE}/ContactUs`, mailData);
	}
}
