import { Injectable, inject } from '@angular/core';

import { ITelemetryPublisher } from './ITelemetryPublisher';
import { TELEMETRY_PUBLISHER } from './TELEMETRY_PUBLISHER';

@Injectable({
	providedIn: 'root',
})
export class TelemetryService implements ITelemetryPublisher {
	private publishers: ITelemetryPublisher[] =
		inject(TELEMETRY_PUBLISHER, { optional: true }) || [];

	constructor() {}

	logEvent(
		name: string,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		this.publishers.forEach((publisher) => {
			publisher.logEvent(name, properties);
		});
	}

	logException(
		exception: Error,
		severityLevel?: number | undefined,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		this.publishers.forEach((publisher) => {
			publisher.logException(exception, severityLevel, properties);
		});
	}

	logTrace(
		message: string,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		this.publishers.forEach((publisher) => {
			publisher.logTrace(message, properties);
		});
	}

	logMetric(
		name: string,
		average: number,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		this.publishers.forEach((publisher) => {
			publisher.logMetric(name, average, properties);
		});
	}

	logPageView(
		name?: string | undefined,
		url?: string | undefined,
		properties?: { [key: string]: unknown } | undefined,
		measurements?: { [key: string]: unknown } | undefined,
		duration?: number | undefined,
	): void {
		this.publishers.forEach((publisher) => {
			publisher.logPageView(
				name,
				url,
				properties,
				measurements,
				duration,
			);
		});
	}
}
