import { Provider } from '@angular/core';
import { TelemetryService } from './telemetry.service';

export const provideTelemetry = (...publisherProviders: Provider[][]) => {
	return [
		...publisherProviders.flat(),
		{
			provide: TelemetryService,
			useClass: TelemetryService,
		},
	];
};
