import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';

import { MsalGuard } from '@azure/msal-angular';
import { environment } from '../environment';
import { provideDebugRoutes } from './routes/debug/provideDebugRoutes';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () =>
			import('./routes/auth/auth.routes').then(
				(m) => m.AuthRoutingModule,
			),
	},
	{
		path: '',
		canActivate: [MsalGuard],
		children: [
			{
				path: 'application',
				loadChildren: () =>
					import('./routes/applications/applications.module').then(
						(m) => m.ApplicationsModule,
					),
			},
			{
				path: 'applicants',
				loadChildren: () =>
					import('./routes/applicants/applicants.module').then(
						(m) => m.ApplicantsModule,
					),
			},
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./tcb-agent/tcb-agent.module').then(
						(m) => m.TcbAgentModule,
					),
			},
			{
				path: 'help',
				loadChildren: () =>
					import('./routes/help/help.module').then(
						(m) => m.HelpModule,
					),
			},
			{
				path: 'resources',
				loadChildren: () =>
					import('./routes/resources/resources.module').then(
						(m) => m.ResourcesModule,
					),
			},
			{
				path: 'register',
				loadChildren: () =>
					import('./routes/registration/registration.module').then(
						(m) => m.RegistrationModule,
					),
			},
			{
				path: 'agent',
				loadChildren: () =>
					import('./routes/agent-profile/agent-profile.module').then(
						(m) => m.AgentProfileModule,
					),
			},
			...provideDebugRoutes(environment.production),
			{
				path: '**',
				redirectTo: 'dashboard',
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation:
				!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
					? 'enabledNonBlocking'
					: 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
