import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { AttachmentsService } from '@tcb/app/services/model/attachments/attachments.service';
import { LoggedInUserService } from '@tcb/app/services/model/logged-in-user/logged-in-user.service';
import { UserDetails } from '@tcb/app/services/page-title.service';
import { MATERIAL_MODULES } from '@tcb/app/shared/material/material.module';
import { DeleteAttachmentResponseJson } from '@tcb/model';
import { map, switchMap } from 'rxjs';

export const removeSpaces = (str: string | null | undefined = ''): string => {
	return str ? str.replace(/ +/g, ' ').trim() : '';
};

@Component({
	selector: 'tcb-contact-us',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		...MATERIAL_MODULES,
	],
	templateUrl: './tcb-contact-us.component.html',
	styleUrl: './tcb-contact-us.component.scss',
})
export class TcbContactUsComponent {
	readonly removeSpaces = removeSpaces;

	public showSuccessMsg: boolean = false;

	private fb = inject(FormBuilder);

	private loggedInUserService = inject(LoggedInUserService);
	private fileAttachmentService = inject(AttachmentsService);

	public contactUsForm: FormGroup = this.fb.group({
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		subject: ['', [Validators.required, Validators.maxLength(200)]],
		assessmentNo: [''],
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		comment: ['', [Validators.required, Validators.maxLength(2000)]],
	});

	get formError(): { [key: string]: AbstractControl } {
		return this.contactUsForm.controls;
	}

	invalid$ = this.contactUsForm.statusChanges.pipe(
		map((status) => status === 'INVALID'),
	);

	agentDetail$ = this.loggedInUserService.userDetails;

	//Contact Us form Submit
	formSubmit() {
		if (this.contactUsForm.invalid) {
			return;
		}
		const subject = removeSpaces(this.contactUsForm.get('subject')?.value);
		const comment = removeSpaces(this.contactUsForm.get('comment')?.value);
		this.contactUsForm?.get('subject')?.setValue(subject);
		this.contactUsForm?.get('comment')?.setValue(comment);
		this.agentDetail$
			.pipe(
				map((res: UserDetails) => {
					return {
						userFirstName: res.contactName,
						userLastName: 'Albert',
						userEmail: res.email,
						subject: this.contactUsForm.get('subject')?.value,
						comment: this.contactUsForm.get('comment')?.value,
					};
				}),
				switchMap((data) => this.fileAttachmentService.sendMail(data)),
			)
			.subscribe((res: DeleteAttachmentResponseJson) => {
				if (res.success) {
					this.showSuccessMsg = true;
				} else {
					this.showSuccessMsg = false;
				}
			});
	}
}
