<mat-toolbar color="grey-1">
	<div class="logo">
		<a
			routerLink="/"
			i18n-title
			title="Back to dashboard"
			i18n-aria-label
			aria-label="Back to dashboard"
		>
			<img
				src="/assets/icons/UL-Solutions.svg"
				alt="UL Solutions Logo"
				i18n-alt
			/>
		</a>
		<mat-divider [vertical]="true"></mat-divider>
		<h1>{{ title$ | async }}</h1>
	</div>

	<div class="toolbar-spacer"></div>
	@if (loginDisplay) {
		<button
			mat-button
			aria-hidden="false"
			i18n-aria-label
			aria-label="My Applicants"
			i18n-title
			title="My Applicants"
			[routerLink]="['/applicants']"
		>
			<mat-icon fontSet="material-icons-outlined">group_outline</mat-icon>
			<span i18n>My Applicants</span>
		</button>

		<button
			mat-button=""
			aria-hidden="false"
			i18n-aria-label
			aria-label="Contact Us"
			i18n-title
			title="Contact Us"
			(click)="launchContactUsDialog()"
		>
			<mat-icon fontSet="material-icons-outlined"
				>contact_mail_outline</mat-icon
			>
			<span i18n>Contact Us</span>
		</button>
	}
	<button
		*ngIf="!hideHelpMenu"
		mat-button
		aria-hidden="false"
		i18n-aria-label
		aria-label="Help"
		i18n-title
		title="Help"
		[routerLink]="['/help']"
	>
		<mat-icon fontIcon="help" fontSet="material-icons-outlined"
			>help_outline</mat-icon
		>
		<span i18n>Help</span>
	</button>

	@if (loginDisplay) {
		<button
			mat-button
			aria-hidden="false"
			i18n-aria-label
			aria-label="Resources"
			i18n-title
			title="Resources"
			routerLink="/resources"
			routerLinkActive="active"
		>
			<mat-icon fontIcon="description" fontSet="material-icons-outlined"
				>description_outline</mat-icon
			><span i18n>Resources</span>
		</button>

		<div class="avatar-menu">
			<div class="avatar-circle" [matMenuTriggerFor]="menu">
				<span>{{ avatarInitial }}</span>
			</div>
			<mat-icon class="avatar-collapse" [matMenuTriggerFor]="menu"
				>expand_more</mat-icon
			>
			<mat-menu #menu="matMenu" xPosition="before">
				<a mat-menu-item [routerLink]="['/agent/profile/edit']"
					><mat-icon fontSet="material-icons-outlined"
						>account_circle</mat-icon
					><span i18n>My profile</span></a
				>

				<a mat-menu-item (click)="changePassword()"
					><mat-icon fontSet="material-icons-outlined">lock</mat-icon
					><span i18n>Change password</span></a
				>

				<a mat-menu-item [routerLink]="['/auth', 'sign-out']"
					><mat-icon>logout</mat-icon><span i18n>Logout</span></a
				>
			</mat-menu>
		</div>
	} @else {
		<button
			i18n
			mat-raised-button
			aria-hidden="false"
			i18n-aria-label
			aria-label="Login"
			i18n-title
			title="Login"
			(click)="login()"
		>
			Login
		</button>
	}

	<!-- @if ((debug$ | async) === true) {
		<button mat-button [matMenuTriggerFor]="menu">DEBUG</button>
		<mat-menu #menu="matMenu" xPosition="before">
			<a mat-menu-item [routerLink]="['/debug/claims']">Claims</a>

			<a mat-menu-item [routerLink]="['/debug/components']">Components</a>
		</mat-menu>
	} -->
</mat-toolbar>

<div class="container">
	<!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
	<router-outlet *ngIf="!isIframe"></router-outlet>
</div>
