import {
	BrowserCacheLocation,
	BrowserSystemOptions,
	Configuration,
	LogLevel,
} from '@azure/msal-browser';
import {
	IAzureB2CPoliciesEnvironment,
	type IAzureB2CAuthorityEnvironment,
	type IAzureB2CEnvironment,
} from '@tcb/environment/IEnvironment';
import * as B2C from './index';

export class B2CConfigBuilder<
	TenantName extends string,
	AuthorityDomainTld extends string = 'b2clogin.com',
	TenantDomainTld extends string = 'onmicrosoft.com',
	AuthorityDomain extends
		`${TenantName}.${AuthorityDomainTld}` = `${TenantName}.${AuthorityDomainTld}`,
	TenantDomain extends
		`${TenantName}.${TenantDomainTld}` = `${TenantName}.${TenantDomainTld}`,
> implements
		IAzureB2CEnvironment<
			TenantName,
			AuthorityDomainTld,
			TenantDomainTld,
			AuthorityDomain,
			TenantDomain
		>
{
	private _authorityDomain!: AuthorityDomain;
	private _cacheLocation?: BrowserCacheLocation;
	private _clientId?: string;
	private _postLogoutRedirectUri?: string;
	private _redirectUri?: string;
	private _resources: Record<string, { endpoint: string; scopes: string[] }> =
		{};
	private _system: BrowserSystemOptions = {
		allowNativeBroker: false,
		loggerOptions: {
			piiLoggingEnabled: false,
			logLevel: LogLevel.Verbose,

			loggerCallback: (level, message) => {
				switch (level) {
					case LogLevel.Error:
						// eslint-disable-next-line no-console
						console.error(message);
						return;
					case LogLevel.Info:
						// eslint-disable-next-line no-console
						console.info(message);
						return;
					case LogLevel.Verbose:
						// eslint-disable-next-line no-console
						console.debug(message);
						return;
					case LogLevel.Warning:
						// eslint-disable-next-line no-console
						console.warn(message);
						return;
					default:
						// eslint-disable-next-line no-console
						console.log(message);
				}
			},
		},
	};
	private _tenantDomain!: TenantDomain;
	private _policies: Map<
		B2C.PolicyType,
		{
			policyId: string;
			authority: `https://${AuthorityDomain}/${TenantDomain}/${Lowercase<string>}`;
		}
	> = new Map();

	constructor(authorityDomain: AuthorityDomain, tenantDomain: TenantDomain) {
		this.authorityDomain = authorityDomain;
		this.tenantDomain = tenantDomain;
	}
	// policies: IAzureB2CPoliciesEnvironment<TenantName, AuthorityDomainTld, TenantDomainTld, AuthorityDomain, TenantDomain>;

	get policies(): IAzureB2CPoliciesEnvironment<
		TenantName,
		AuthorityDomainTld,
		TenantDomainTld,
		AuthorityDomain,
		TenantDomain
	> {
		return {
			authorities: this.authorities,
			names: this.names,
			authorityDomain: this.authorityDomain,
		} as IAzureB2CPoliciesEnvironment<
			TenantName,
			AuthorityDomainTld,
			TenantDomainTld,
			AuthorityDomain,
			TenantDomain
		>;
	}

	get config() {
		return this.toMsalConfiguration();
	}

	get authorities(): Record<
		string,
		IAzureB2CAuthorityEnvironment<
			TenantName,
			AuthorityDomainTld,
			TenantDomainTld,
			AuthorityDomain,
			TenantDomain
		>
	> {
		return Object.fromEntries(
			[...this._policies.entries()].map(([policyType, { authority }]) => [
				policyType,
				{ authority },
			]),
		);
	}

	get authorityDomain(): AuthorityDomain {
		return this._authorityDomain;
	}

	set authorityDomain(authorityDomain: AuthorityDomain) {
		this._authorityDomain = authorityDomain;
	}

	get cacheLocation(): BrowserCacheLocation {
		if (this._cacheLocation) {
			return this._cacheLocation;
		}
		throw new Error('Cache Location not set');
	}

	set cacheLocation(cacheLocation: BrowserCacheLocation) {
		this._cacheLocation = cacheLocation;
	}

	get clientId(): string {
		if (this._clientId) {
			return this._clientId;
		}
		throw new Error('Client ID not set');
	}

	set clientId(clientId: string) {
		this._clientId = clientId;
	}

	get names(): Record<string, string> {
		return Object.fromEntries(
			[...this._policies.entries()].map(([policyType, { policyId }]) => [
				policyType,
				policyId,
			]),
		);
	}

	get protectedResources(): Record<
		string,
		{ endpoint: string; scopes: string[] }
	> {
		return this._resources;
	}

	get postLogoutRedirectUri(): string {
		if (this._postLogoutRedirectUri) {
			return this._postLogoutRedirectUri;
		}
		throw new Error('Post Logout Redirect URI not set');
	}

	set postLogoutRedirectUri(postLogoutRedirectUri: string) {
		this._postLogoutRedirectUri = postLogoutRedirectUri;
	}

	get redirectUri(): string {
		if (this._redirectUri) {
			return this._redirectUri;
		}
		throw new Error('Redirect URI not set');
	}

	set redirectUri(redirectUri: string) {
		this._redirectUri = redirectUri;
	}

	get system(): BrowserSystemOptions {
		return this._system;
	}

	set system(system: BrowserSystemOptions) {
		this._system = system;
	}

	get tenantDomain(): TenantDomain {
		return this._tenantDomain;
	}

	set tenantDomain(tenantDomain: TenantDomain) {
		this._tenantDomain = tenantDomain;
	}

	build(): IAzureB2CEnvironment<
		TenantName,
		AuthorityDomainTld,
		TenantDomainTld,
		AuthorityDomain,
		TenantDomain
	> {
		return new B2C.Environment(
			{
				names: this.names,
				authorities: this.authorities,
				authorityDomain: this.authorityDomain,
			},
			this.toMsalConfiguration(),
			this.protectedResources,
		);
	}

	getAuthorityByPolicyType(policyType: B2C.PolicyType): string {
		if (this._policies.has(policyType)) {
			return this._policies.get(policyType)!.authority;
		} else {
			throw new Error(`Policy authority ${policyType} not found`);
		}
	}

	getPolicyIdByPolicyType(policyType: B2C.PolicyType): string {
		if (this._policies.has(policyType)) {
			return this._policies.get(policyType)!.policyId;
		} else {
			throw new Error(`Policy name ${policyType} not found`);
		}
	}

	setCacheLocation(cacheLocation: BrowserCacheLocation): this {
		this.cacheLocation = cacheLocation;
		return this;
	}

	setClientId(clientId: string): this {
		this.clientId = clientId;
		return this;
	}

	setPolicy(
		policyType: B2C.PolicyType,
		azurePolicyId: string,
		authority?: `https://${AuthorityDomain}/${TenantDomain}/${Lowercase<string>}`,
	): this {
		this._policies.set(policyType, {
			policyId: azurePolicyId,
			authority: authority
				? authority
				: `https://${this.authorityDomain}/${this.tenantDomain}/${
						azurePolicyId.toLowerCase() as Lowercase<string>
				  }`,
		});

		return this;
	}

	setPostLogoutRedirectUri(postLogoutRedirectUri: string): this {
		this.postLogoutRedirectUri = postLogoutRedirectUri;
		return this;
	}

	setRedirectUri(redirectUri: string): this {
		this.redirectUri = redirectUri;
		return this;
	}

	addProtectedResource(
		resourceId: string,
		endpoint: string,
		scopes: string[],
	): this {
		this._resources[resourceId] = { endpoint, scopes };
		return this;
	}

	setSystem(system: BrowserSystemOptions): this {
		this.system = system;
		return this;
	}

	toMsalConfiguration(): Configuration {
		return {
			auth: {
				clientId: this.clientId,
				authority: this.authorityDomain,
				knownAuthorities: [this.authorityDomain],
				redirectUri: this.redirectUri,
				postLogoutRedirectUri: this.postLogoutRedirectUri,
			},
			cache: {
				cacheLocation: this.cacheLocation,
			},
			system: this.system,
		} as Configuration;
	}
}
