import { InjectionToken } from '@angular/core';
import { type Observable } from 'rxjs';

export interface IExhibitType {
	exhibitTypeId: number;
	title: string;
}

/**
 * Query interface for IExhibitTypesService.getExhibitTypes. Used to create strongly-typed queries.
 * @prop {number?} exhibitTypeId ID of IExhibitType to read
 * @prop {number?} pageIdx Index of page to read
 */
export interface IExhibitTypesQuery {
	exhibitTypeId?: number;
	pageIdx?: number;
}

export interface IExhibitTypesService {
	getExhibitTypes(query?: IExhibitTypesQuery): Observable<IExhibitType[]>;
	createExhibitType(
		exhibitType: Omit<IExhibitType, 'exhibitTypeId'>,
	): Observable<IExhibitType>;
	getExhibitType(exhibitTypeId: number): Observable<IExhibitType>;
	updateExhibitType(exhibitType: IExhibitType): Observable<IExhibitType>;
	deleteExhibitType(exhibitType: IExhibitType): Observable<boolean>;
}

export const ExhibitTypesService = new InjectionToken<IExhibitTypesService>(
	'IExhibitTypesService',
	{
		providedIn: 'root',
		factory: () => new HttpExhibitTypesService(),
	},
);

import { StrictlyImplements } from '@tcb/app/interfaces/strictly-implements.interface';
type _ = StrictlyImplements<
	keyof IExhibitTypesService,
	keyof HttpExhibitTypesService
>;

export class HttpExhibitTypesService implements IExhibitTypesService {
	getExhibitTypes(query?: IExhibitTypesQuery): Observable<IExhibitType[]> {
		throw new Error(
			'Method not implemented. ' +
				JSON.stringify({
					query,
				}),
		);
	}
	createExhibitType(
		exhibitType: Omit<IExhibitType, 'exhibitTypeId'>,
	): Observable<IExhibitType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ exhibitType }),
		);
	}
	getExhibitType(exhibitTypeId: number): Observable<IExhibitType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ exhibitTypeId }),
		);
	}
	updateExhibitType(exhibitType: IExhibitType): Observable<IExhibitType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ exhibitType }),
		);
	}
	deleteExhibitType(exhibitType: IExhibitType): Observable<boolean> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ exhibitType }),
		);
	}
}
