import { InjectionToken } from '@angular/core';

export const TCB_API_BASE = new InjectionToken<string>('API_BASE');

export const provideTcbApiBase = (apiBase: string) => {
	return {
		provide: TCB_API_BASE,
		useValue: apiBase.replace(/\/+$/, ''),
	};
};
