import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { Azure } from '../utility/configuration';
import { type IEnvironment } from './IEnvironment.d';

import { FakeExhibitTypesService } from '@tcb/app/services/model/exhibit-types/exhibit-types.service.fake';
import { FakeRadioEquipmentTypesService } from '@tcb/app/services/model/radio-equipment-types/radio-equipment-types.service.fake';
import { withConsolePublisher } from '@tcb/app/services/telemetry/console/withConsolePublisher';

type TenantName = 'testulplatform';
type AuthorityDomainTld = 'b2clogin.com';
type TenantDomainTld = 'onmicrosoft.com';

export const b2c = {
	logLevel: LogLevel.Warning,
	signUpSignInPolicyName: 'B2C_1A_Signup_Signin_Staging',
	passwordResetPolicyName: 'B2C_1A_Password_Reset_Staging',
	forgotPasswordPolicyName: 'b2c_1a_forgot_password_development',
	changeEmailAddressPolicyName: 'b2c_1a_change_email_address_development',
	authorityDomain: 'testulplatform.b2clogin.com',
	authorityBase:
		'https://testulplatform.b2clogin.com/testulplatform.onmicrosoft.com',
	defaultClientId: 'c00c8354-0093-4152-8d44-5a5a8b9f1045', // Change this to the client ID of your app registration
	migrationOnDemandClientId: '267459b7-0916-4a0b-9a3c-734a06932c8e',
	scope: 'openid profile https://testulplatform.onmicrosoft.com/api/read offline_access', // Important - Include offline_access to also request a refresh token
	loginRedirectUri: '/auth/sign-in', // Change this to the redirect URI for your app registration for signing in
	logoutRedirectUri: '/auth/sign-out',
};

export const protectedResourceMap = new Map<string, string[]>([
	[
		'/tcb/',
		['https://testulplatform.onmicrosoft.com/TCB-CustomerFacing-API/Read'],
	],
]);

export const environment: IEnvironment<'testulplatform'> = {
	production: false,
	telemetry: [withConsolePublisher()],
	providers: [
		// FakeApplicantsService.provideAsApplicantsService(),
		// FakeApplicationListService.provideAsApplicationListService(),
		FakeExhibitTypesService.provideAsExhibitTypesService(),
		FakeRadioEquipmentTypesService.provideAsRadioEquipmentTypesService(),
	],
	tcbApi: {
		base: 'https://di-staging.ulplatform.com/tcb/v1',

		subscriptionKey: '941c02666c4d47719a6409042180018d',
		//'078de599a9e54b32b625a53e46c28676',
		APIM: 'true',
	},
	menuAccess: {
		helpMenu: true,
	},
	emailService: {
		submitApplication: 'WirelessCB@ul.com',
		icCompanyNumber:
			'ic.certificationbureau-bureauhomologation.ic@canada.ca',
	},
	resourcePricing: {
		excelFileUpdatedDate: 'October 30, 2023',
		attestationLetterUpdatedDate: 'February 6, 2023',
		pricingFormUpdatedDate: 'October 24, 2023',
	},
	azure: {
		b2c: new Azure.B2C.ConfigBuilder<
			TenantName,
			AuthorityDomainTld,
			TenantDomainTld
		>('testulplatform.b2clogin.com', 'testulplatform.onmicrosoft.com')
			.setClientId('c00c8354-0093-4152-8d44-5a5a8b9f1045')
			.setCacheLocation(BrowserCacheLocation.LocalStorage)
			.setRedirectUri('/auth/sign-in')
			.setPostLogoutRedirectUri('/auth/sign-out')
			.setPolicy(
				Azure.B2C.PolicyType.SignUpSignIn,
				'B2C_1A_Signup_Signin_Staging',
				// 'https://testulplatform.b2clogin.com/testulplatform.onmicrosoft.com/b2c_1a_signup_signin_staging',
			)
			.setPolicy(
				Azure.B2C.PolicyType.ResetPassword,
				'B2C_1A_Password_Reset_Staging',
				// 'https://testulplatform.b2clogin.com/testulplatform.onmicrosoft.com/b2c_1a_password_reset_staging',
			)
			.setPolicy(
				Azure.B2C.PolicyType.EditProfile,
				'B2C_1A_Profile_Edit_Staging',
				// 'https://testulplatform.b2clogin.com/testulplatform.onmicrosoft.com/b2c_1a_profile_edit_staging',
			)
			.addProtectedResource(
				'graphMe',
				'https://graph.microsoft.com/v1.0/me',
				['User.Read'],
			)
			.addProtectedResource(
				'graphContacts',
				'https://graph.microsoft.com/v1.0/me/contacts',
				['Contacts.Read'],
			),

		appInsights: {
			connectionString:
				'InstrumentationKey=e0e023e8-83c7-46ce-9450-9cff5451f91b;IngestionEndpoint=https://eastus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
		},
	},
};
