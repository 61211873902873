import { Provider } from '@angular/core';
import { environment } from '@tcb/environment';

/**
 * Returns an array of providers that provide telemetry functionality.
 * @returns The provider array from environment-specific configuration.
 */
export const withEnvironmentTelemetry = (): Provider[] => {
	return [...environment.telemetry];
};
