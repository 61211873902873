import { InjectionToken } from '@angular/core';
import { type Observable } from 'rxjs';

export interface IRadioEquipmentType {
	radioEquipmentTypeId: number;
	title: string;
}

/**
 * Query interface for IRadioEquipmentTypesService.getRadioEquipmentTypes. Used to create strongly-typed queries.
 * @prop {number?} radioEquipmentTypeId ID of IRadioEquipmentType to read
 * @prop {number?} pageIdx Index of page to read
 */
export interface IRadioEquipmentTypesQuery {
	radioEquipmentTypeId?: number;
	pageIdx?: number;
}

export interface IRadioEquipmentTypesService {
	getRadioEquipmentTypes(
		query?: IRadioEquipmentTypesQuery,
	): Observable<IRadioEquipmentType[]>;
	createRadioEquipmentType(
		radioEquipmentType: Omit<IRadioEquipmentType, 'radioEquipmentTypeId'>,
	): Observable<IRadioEquipmentType>;
	getRadioEquipmentType(
		radioEquipmentTypeId: number,
	): Observable<IRadioEquipmentType>;
	updateRadioEquipmentType(
		radioEquipmentType: IRadioEquipmentType,
	): Observable<IRadioEquipmentType>;
	deleteRadioEquipmentType(
		radioEquipmentType: IRadioEquipmentType,
	): Observable<boolean>;
}

export const RadioEquipmentTypesService =
	new InjectionToken<IRadioEquipmentTypesService>(
		'IRadioEquipmentTypesService',
		{
			providedIn: 'root',
			factory: () => new HttpRadioEquipmentTypesService(),
		},
	);

import { StrictlyImplements } from '@tcb/app/interfaces/strictly-implements.interface';
type _ = StrictlyImplements<
	keyof IRadioEquipmentTypesService,
	keyof HttpRadioEquipmentTypesService
>;

export class HttpRadioEquipmentTypesService
	implements IRadioEquipmentTypesService
{
	getRadioEquipmentTypes(
		query?: IRadioEquipmentTypesQuery,
	): Observable<IRadioEquipmentType[]> {
		throw new Error(
			'Method not implemented. ' +
				JSON.stringify({
					query,
				}),
		);
	}
	createRadioEquipmentType(
		radioEquipmentType: Omit<IRadioEquipmentType, 'radioEquipmentTypeId'>,
	): Observable<IRadioEquipmentType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ radioEquipmentType }),
		);
	}
	getRadioEquipmentType(
		radioEquipmentTypeId: number,
	): Observable<IRadioEquipmentType> {
		throw new Error(
			'Method not implemented. ' +
				JSON.stringify({ radioEquipmentTypeId }),
		);
	}
	updateRadioEquipmentType(
		radioEquipmentType: IRadioEquipmentType,
	): Observable<IRadioEquipmentType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ radioEquipmentType }),
		);
	}
	deleteRadioEquipmentType(
		radioEquipmentType: IRadioEquipmentType,
	): Observable<boolean> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ radioEquipmentType }),
		);
	}
}
