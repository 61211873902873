import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

export interface UserDetails {
	contactName: string;
	email: string;
}

@Injectable({
	providedIn: 'root',
})
export class PageTitleService {
	private titleService = inject(Title);
	private title = new BehaviorSubject<string>('TCB');

	public readonly title$ = this.title.asObservable();

	setTitle(title: string) {
		if (this.title.value === title) {
			return;
		}

		this.titleService.setTitle(`${title} - TCB - UL Solutions`);

		this.title.next(title);
	}
}
