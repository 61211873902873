import { ClassProvider } from '@angular/core';
import { type Observable } from 'rxjs';
import {
	ExhibitTypesService,
	IExhibitType,
	IExhibitTypesQuery,
	IExhibitTypesService,
} from './exhibit-types.service';

import { StrictlyImplements } from '@tcb/app/interfaces/strictly-implements.interface';
type _ = StrictlyImplements<
	keyof IExhibitTypesService,
	keyof FakeExhibitTypesService
>;
export class FakeExhibitTypesService implements IExhibitTypesService {
	static provideAsExhibitTypesService(): ClassProvider {
		return {
			provide: ExhibitTypesService,
			useClass: FakeExhibitTypesService,
		};
	}
	getExhibitTypes(query?: IExhibitTypesQuery): Observable<IExhibitType[]> {
		throw new Error(
			'Method not implemented. ' +
				JSON.stringify({
					query,
				}),
		);
	}
	createExhibitType(
		exhibitType: Omit<IExhibitType, 'exhibitTypeId'>,
	): Observable<IExhibitType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ exhibitType }),
		);
	}
	getExhibitType(exhibitTypeId: number): Observable<IExhibitType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ exhibitTypeId }),
		);
	}
	updateExhibitType(exhibitType: IExhibitType): Observable<IExhibitType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ exhibitType }),
		);
	}
	deleteExhibitType(exhibitType: IExhibitType): Observable<boolean> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ exhibitType }),
		);
	}
}
