import { MsalGuard } from '@azure/msal-angular';
import { ClaimsComponent } from './claims/claims.component';
import { ComponentsComponent } from './components/components.component';

export const provideDebugRoutes = (isProduction: boolean) =>
	isProduction
		? []
		: [
				{
					path: 'debug',
					canActivate: [MsalGuard],
					children: [
						{
							path: 'claims',
							component: ClaimsComponent,
						},
						{ path: 'components', component: ComponentsComponent },
						{
							path: '**',
							redirectTo: 'claims',
						},
					],
				},
		  ];
