import { MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { protectedResourceMap } from '@tcb/environment';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}
