import { HTTP_INTERCEPTORS, HttpInterceptor } from '@angular/common/http';

export const provideRequestHeaders = (
	setHeaders: Record<string, string> = {},
) => {
	return {
		provide: HTTP_INTERCEPTORS,
		useFactory: (): HttpInterceptor => {
			return {
				intercept(req, next) {
					return next.handle(
						req.clone({
							setHeaders,
						}),
					);
				},
			};
		},
		multi: true,
	};
};
