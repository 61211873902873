<div class="app-contact-us">
	<!-- Contact Us Form -->
	<ng-container *ngIf="!showSuccessMsg">
		<form [formGroup]="contactUsForm" (ngSubmit)="formSubmit()">
			<div>
				<h4 i18n>Contact Us</h4>
				<mat-icon class="dialog-close" mat-dialog-close>close</mat-icon>
				<p i18n>
					Questions about the application? Need help providing
					required information? Our team is here to help guide you
					through the process. An application specialist will reach
					out to you shortly with next steps and answers.
				</p>

				<div class="agent-details">
					<mat-label i18n>Agent Information</mat-label>
					<div class="agent-information">
						<div class="agent-label">
							<h4 i18n>Contact Name</h4>
							<h4 i18n>Email</h4>
						</div>
						<div class="agent-info">
							@if (agentDetail$ | async; as agent) {
								<span i18n>{{ agent.contactName }}</span>
								<span i18n>{{ agent.email }}</span>
							}
						</div>
					</div>
				</div>

				<mat-form-field class="subject" appearance="outline">
					<mat-label i18n> Subject Line</mat-label>
					<input type="text" formControlName="subject" matInput />
					<mat-error
						i18n
						*ngIf="formError['subject'].hasError('required')"
					>
						Subject is required
					</mat-error>
					<mat-error
						i18n
						*ngIf="
							!formError['subject'].hasError('required') &&
							formError['subject'].hasError('maxlength')
						"
					>
						Subject should be maximum length of 200 characters
					</mat-error>
				</mat-form-field>
				<br />

				<!-- Commented for now as assessment no not in use. maybe in future will be in used as per business need -->
				<!-- <mat-form-field class="assessment" appearance="outline">
					<mat-label> Assessment Number</mat-label>
					<mat-select formControlName="assessmentNo">
						<mat-option value="1"> Assessment - 1</mat-option>
						<mat-option value="2"> Assessment - 2</mat-option>
						<mat-option value="3"> Assessment - 3</mat-option>
					</mat-select> </mat-form-field
				><br /> -->

				<mat-form-field class="comment" appearance="outline">
					<mat-label i18n> Comment</mat-label>
					<textarea
						matInput
						formControlName="comment"
						cdkAutosizeMaxRows="30"
					></textarea>
					<mat-error
						i18n
						*ngIf="formError['comment'].hasError('required')"
					>
						Comment is required
					</mat-error>
					<mat-error
						i18n
						*ngIf="
							!formError['comment'].hasError('required') &&
							formError['comment'].hasError('maxlength')
						"
					>
						Comment should be maximum length of 2000 characters
					</mat-error> </mat-form-field
				><br />
			</div>
			<div class="buttons-group">
				<button
					type="button"
					mat-dialog-close
					mat-button
					color="primary"
					i18n
				>
					Cancel
				</button>
				<button type="submit" mat-raised-button color="primary" i18n>
					Submit
				</button>
			</div>
		</form>
	</ng-container>
	<!-- Success Message -->
	<ng-container *ngIf="showSuccessMsg">
		<mat-icon class="dialog-close" mat-dialog-close>close</mat-icon>
		<div class="message">
			<img
				class="menu-icons"
				src="/assets/icons/done.svg"
				alt="done"
				i18n-alt
			/>
			<h4 i18n>Message Sent</h4>
			<p i18n>
				We have received your Contact Us message and will be in touch
				with you shortly.
			</p>
		</div>
		<div class="close-action">
			<button
				type="button"
				mat-raised-button
				mat-dialog-close
				color="primary"
				i18n
			>
				Close
			</button>
		</div>
	</ng-container>
</div>
