import { HTTP_INTERCEPTORS, HttpInterceptor } from '@angular/common/http';

export const provideRewrittenHeaders = (
	sourceName: string,
	destinationName: string,
	transformFn = (value: string) => value,
) => {
	return {
		provide: HTTP_INTERCEPTORS,
		useFactory: (): HttpInterceptor => {
			return {
				intercept(req, next) {
					const setHeaders: Record<string, string | string[]> = {};
					const value = req.headers.get(sourceName);
					if (value) {
						setHeaders[destinationName] = transformFn(value);
					}
					return next.handle(
						req.clone({
							setHeaders,
						}),
					);
				},
			};
		},
		multi: true,
	};
};
