import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { createClaimsTable } from '@tcb/app/routes/debug/claim-utils';
import { PageTitleService } from '@tcb/app/services/page-title.service';
import { filter, map, share, startWith } from 'rxjs';

@Component({
	selector: 'tcb-claims',
	standalone: true,
	imports: [CommonModule, MatCardModule, MatTableModule],
	templateUrl: './claims.component.html',
	styleUrl: './claims.component.scss',
})
export class ClaimsComponent {
	displayedColumns: string[] = ['claim', 'value', 'description'];

	private authService = inject(MsalService);
	private msalBroadcastService = inject(MsalBroadcastService);

	private pageTitleService = inject(PageTitleService);

	constructor() {
		this.pageTitleService.setTitle('Claims');
	}

	private ready$ = this.msalBroadcastService.inProgress$.pipe(
		filter(
			(status: InteractionStatus) => status === InteractionStatus.None,
		),
		share(),
	);

	loginDisplay$ = this.ready$.pipe(
		startWith(false),
		map(() => this.authService.instance.getAllAccounts().length > 0),
	);

	claims$ = this.ready$.pipe(
		startWith([]),
		map(() => this.authService.instance.getActiveAccount()),
		filter((account): account is AccountInfo => !!account),
		map((account) => {
			if (account.idTokenClaims) {
				return createClaimsTable(
					account.idTokenClaims as Record<string, string>,
				);
			} else {
				return [];
			}
		}),
	);
}
