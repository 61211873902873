import { Configuration as MsalConfiguration } from '@azure/msal-browser';
import {
	IAzureB2CEnvironment,
	IAzureB2CPoliciesEnvironment,
} from '../../../../environment/IEnvironment';

export class B2CEnvironment<
	TenantName extends string,
	AuthorityDomainTld extends string = 'b2clogin.com',
	TenantDomainTld extends string = 'onmicrosoft.com',
	AuthorityDomain extends
		`${TenantName}.${AuthorityDomainTld}` = `${TenantName}.${AuthorityDomainTld}`,
	TenantDomain extends
		`${TenantName}.${TenantDomainTld}` = `${TenantName}.${TenantDomainTld}`,
> implements
		IAzureB2CEnvironment<
			TenantName,
			AuthorityDomainTld,
			TenantDomainTld,
			AuthorityDomain,
			TenantDomain
		>
{
	constructor(
		public readonly policies: IAzureB2CPoliciesEnvironment<
			TenantName,
			AuthorityDomainTld,
			TenantDomainTld,
			AuthorityDomain,
			TenantDomain
		>,
		public readonly config: MsalConfiguration,
		public readonly protectedResources: Record<
			string,
			{ endpoint: string; scopes: string[] }
		>,
	) {}
}
