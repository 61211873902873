import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService,
	MsalGuard,
	MsalInterceptor,
	MsalService,
} from '@azure/msal-angular';
import { environment } from '@tcb/environment';
import { AppRoutingModule } from './app-routing.module';
import { MSALGuardConfigFactory } from './msal-guard.factory';
import { MSALInstanceFactory } from './msal-instance.factory';
import { MSALInterceptorConfigFactory } from './msal-interceptor-config.factory';
import { provideTcbApiBase } from './services/model/TCB_API_BASE.token';
import { provideTelemetry } from './services/telemetry/provideTelemetry';
import { withEnvironmentTelemetry } from './services/telemetry/withEnvironmentTelemetry';
import { provideRequestHeaders } from './shared/interceptors/provide-request-headers/provide-request-headers.interceptor';
import { provideRewrittenHeaders } from './shared/interceptors/provide-rewritten-headers/provide-rewritten-headers.interceptor';
import { MaterialModule } from './shared/material/material.module';

export const appConfig: ApplicationConfig = {
	providers: [
		importProvidersFrom(BrowserModule, AppRoutingModule, MaterialModule),
		{
			provide: HTTP_INTERCEPTORS, // Provides as HTTP Interceptor
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		provideRequestHeaders({
			'UL-Subscription-Key': environment.tcbApi.subscriptionKey,
			APIM: environment.tcbApi.APIM,
		}),
		provideRewrittenHeaders('Authorization', 'b2cAccessToken', (value) =>
			value.startsWith('Bearer ') ? value.split(/\s+/)[1] : value,
		),

		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				maxHeight: `calc(100vh - 64px)`,
				maxWidth: `calc(100vw - 64px)`,
			},
		},

		MsalService,
		MsalGuard,
		MsalBroadcastService,
		provideTcbApiBase(environment.tcbApi.base),
		provideHttpClient(withInterceptorsFromDi()),
		provideTelemetry(withEnvironmentTelemetry()),
		provideAnimations(),

		// any other root-level overrides can be done in the environment file
		...environment.providers,
	],
};
