import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
export const MATERIAL_MODULES = [
	MatCardModule,
	MatIconModule,
	MatMenuModule,
	MatListModule,
	MatInputModule,
	MatTableModule,
	MatRadioModule,
	MatDialogModule,
	MatSelectModule,
	MatButtonModule,
	MatTooltipModule,
	MatToolbarModule,
	MatStepperModule,
	MatDividerModule,
	MatCheckboxModule,
	MatExpansionModule,
	MatPaginatorModule,
	MatFormFieldModule,
	MatNativeDateModule,
	MatDatepickerModule,
	MatSortModule,
	MatProgressSpinnerModule,
	MatAutocompleteModule,
];

@NgModule({
	declarations: [],
	imports: [CommonModule, ...MATERIAL_MODULES],
	exports: [...MATERIAL_MODULES],
})
export class MaterialModule {}
