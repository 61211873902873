import { ClassProvider } from '@angular/core';
import { type Observable } from 'rxjs';
import {
	IRadioEquipmentType,
	IRadioEquipmentTypesQuery,
	IRadioEquipmentTypesService,
	RadioEquipmentTypesService,
} from './radio-equipment-types.service';

import { StrictlyImplements } from '@tcb/app/interfaces/strictly-implements.interface';
type _ = StrictlyImplements<
	keyof IRadioEquipmentTypesService,
	keyof FakeRadioEquipmentTypesService
>;
export class FakeRadioEquipmentTypesService implements IRadioEquipmentTypesService {
	static provideAsRadioEquipmentTypesService(): ClassProvider {
		return {
			provide: RadioEquipmentTypesService,
			useClass: FakeRadioEquipmentTypesService,
		};
	}
	getRadioEquipmentTypes(
		query?: IRadioEquipmentTypesQuery,
	): Observable<IRadioEquipmentType[]> {
		throw new Error(
			'Method not implemented. ' +
				JSON.stringify({
					query,
				}),
		);
	}
	createRadioEquipmentType(
		radioEquipmentType: Omit<IRadioEquipmentType, 'radioEquipmentTypeId'>,
	): Observable<IRadioEquipmentType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ radioEquipmentType }),
		);
	}
	getRadioEquipmentType(
		radioEquipmentTypeId: number,
	): Observable<IRadioEquipmentType> {
		throw new Error(
			'Method not implemented. ' +
				JSON.stringify({ radioEquipmentTypeId }),
		);
	}
	updateRadioEquipmentType(
		radioEquipmentType: IRadioEquipmentType,
	): Observable<IRadioEquipmentType> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ radioEquipmentType }),
		);
	}
	deleteRadioEquipmentType(
		radioEquipmentType: IRadioEquipmentType,
	): Observable<boolean> {
		throw new Error(
			'Method not implemented. ' + JSON.stringify({ radioEquipmentType }),
		);
	}
}
